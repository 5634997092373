import * as React from "react"
import { Container, Row, Col} from "react-bootstrap"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { Icon } from "semantic-ui-react";

export default function About({location}) {
  
  const edu_color = "var(--secondary-color)";
  const work_color = "var(--primary-color)";
  
  return (
    <Layout lang='de' location={location}>
      <SEO lang='de' title="Über mich"/>
      <Container fluid className="h-100 d-flex flex-column">
        <VerticalTimeline className="flex-grow-1">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            contentArrowStyle={{ borderRight: `7px solid  ${work_color}` }}
            date="2021 bis heute"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)' }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Oberarzt</h3>
            <h4 className="vertical-timeline-element-subtitle">A.ö.KH Oberpullendorf Abteilung für Chirurgie</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2017 - 2021"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Betreuung der Hernienambulanz im LKH Oberwart</h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2015 - 2021"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Oberarzt</h3>
            <h4 className="vertical-timeline-element-subtitle">LKH Oberwart Abteilung für Chirurgie</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2014 - 2015"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Vorstand der chirurgischen Abteilung LKH Szekszárd</h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2002 - 2013"
            iconStyle={{ background: work_color,color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Oberarzt</h3>
            <h4 className="vertical-timeline-element-subtitle">LKH Székesfehérvár Abteilung für Chirurgie</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2000 - 2002"
            contentStyle={{ background: edu_color, color: '#fff' }}
            contentArrowStyle={{ borderRight: `7px solid  ${edu_color}` }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Ausbildung zum Facharzt für Viszeralchirurgie</h3>
            <h4 className="vertical-timeline-element-subtitle">Universitätsklinik Szeged/LKH Székesfehérvár Abteilung für Chirurgie</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="1994 - 2000"
            contentStyle={{ background: edu_color, color: '#fff' }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Ausbildung zum Facharzt für Allgemeinchirurgie</h3>
            <h4 className="vertical-timeline-element-subtitle">Universitätsklinik/LKH Szeged Abteilung für Chirurgie</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="1988 - 1994"
            contentStyle={{ background: edu_color, color: '#fff' }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Studium an der medizinischen Universität Szeged</h3>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Container>
    </Layout>
  )
}